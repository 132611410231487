<template>
  <div class="text-general row">
    <div class="col-md-8">
      <p class="lead">
        The best way to contact me is by email or in Telegram. <br />
        Also I have accounts on these services:
      </p>

      <ul>
        <li><a href="https://github.com/kribesk">Github</a></li>
        <li><a href="https://soundcloud.com/kribesk/">Soundcloud</a></li>
        <li><a href="http://www.last.fm/user/kribesk">Last.fm</a></li>
        <li><a href="http://www.kinopoisk.ru/user/3973554/">Kinopoisk</a></li>
        <li>
          <a href="https://www.goodreads.com/user/show/44159291-kribesk"
            >Goodreads</a
          >
        </li>
      </ul>
    </div>

    <div class="col-md-4">
      <a
        class="btn btn-lg btn-block mb-3 btn-outline-dark"
        role="button"
        href="mailto:inbox@kribesk.dev"
      >
        <i class="far fa-envelope"></i> Send me email
      </a>

      <a
        class="btn btn-lg btn-block mb-3 btn-outline-dark"
        role="button"
        href="https://t.me/kribesk"
      >
        <i class="fab fa-telegram-plane"></i> Text in telegram
      </a>

      <div class="card border-secondary" id="social">
        <div class="card-body">
          <span>
            <a
              class="btn mr-2 mb-2 btn-social-icon btn-lg btn-facebook"
              href="https://www.facebook.com/kribesk"
            >
              <i class="fab fa-facebook-f"></i>
            </a>
            <a
              class="btn mr-2 mb-2 btn-social-icon btn-lg btn-vk"
              href="https://www.vk.com/kribesk"
            >
              <i class="fab fa-vk"></i>
            </a>
          </span>
          <span>
            <a
              class="btn mr-2 mb-2 btn-social-icon btn-lg btn-instagram"
              href="https://instagram.com/kribesk/"
            >
              <i class="fab fa-instagram"></i>
            </a>
            <a
              class="btn mr-2 mb-2 btn-social-icon btn-lg btn-linkedin"
              href="https://linkedin.com/in/kribesk/"
            >
              <i class="fab fa-linkedin"></i>
            </a>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "bootstrap-social/bootstrap-social.css";

export default {
  name: "Contacts",
};
</script>

<style scoped>
#social {
  border-radius: 0.3rem;
  background-color: transparent;
}

#social .card-body {
  text-align: center;
}

#social span {
  display: inline-block;
}
</style>
